import { useEffect } from 'react'

const Page = () => {
  useEffect(() => {
    window.location.href = 'https://www.prontopro.it/people/careers'
  }, [])

  return null
}

export default Page
